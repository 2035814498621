<template>
  <v-card class="mt-5 relative" flat>
    <div class="d-flex align-center">
      <v-card-title>Quy trình xử lý</v-card-title>
      <div
        class="ml-auto d-flex align-center mr-3"
        v-if="guaranteeVoucher.export_id"
      >
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              :class="[
                'mr-3 text-body-1 primary--text font-weight-medium cursor-pointer',
                {
                  'cursor-wait':
                    orderStatusRequest.value === 'loading-getOrderById'
                }
              ]"
              v-bind="attrs"
              v-on="on"
              @click.stop="viewSaleReceipt(guaranteeVoucher.export_id)"
            >
              {{ guaranteeVoucher.export.code }}
            </span>
          </template>
          <span>Xem chi tiết</span>
        </v-tooltip>

        <v-chip
          v-if="guaranteeVoucher.export.status === 1"
          class="font-weight-bold"
          color="green"
          outlined
          small
        >
          Thành công
        </v-chip>

        <v-chip
          v-if="guaranteeVoucher.export.status === -1"
          class="font-weight-bold"
          color="red"
          outlined
          small
        >
          Huỷ
        </v-chip>

        <v-btn
          v-if="guaranteeVoucher.export_id && type === 'repair'"
          icon
          color="primary"
          class="ml-3"
          :to="{
            name: 'print_sale-receipt',
            params: {
              saleReceiptId: guaranteeVoucher.export_id
            }
          }"
          target="_blank"
        >
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </div>
    </div>

    <v-container class="px-1 pt-0 pb-5" fluid>
      <v-row class="mx-0" style="height: 100%">
        <!-- Start: Step Col -->
        <v-col class="col-wrap py-0" cols="3" v-for="num in 4" :key="num">
          <col-step :step="num" :isButton="type"></col-step>
        </v-col>
        <!-- End: Step col -->
      </v-row>
    </v-container>
    <modal-confirm-give-customer :type="type" />
  </v-card>
</template>

<script>
import ColStep from "./components/ColStep";
import ModalConfirmGiveCustomer from "@/modules/Guarantee/pages/GuaranteeVouchers/Detail/components/modal/ModalConfirmGiveCustomer";

export default {
  props: {
    type: {
      type: String,
      require: true
    }
  },
  components: {
    ColStep,
    ModalConfirmGiveCustomer
  },
  computed: {
    guaranteeVoucher() {
      return this.$store.getters["GUARANTEE_VOUCHER/guaranteeVoucher"];
    },
    serialInfo() {
      return this.$store.getters["GUARANTEE_VOUCHER/serialInfo"];
    },
    orderStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    }
  },
  created() {
    if (this.serialInfo) {
      this.serialNumber = this.serialInfo.serial_number;
      this.guaranteeVoucher.serial_id = this.serialInfo.id;
    }
  },
  methods: {
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },

    async viewSaleReceipt(id) {
      await this.$store.dispatch("SALE_RECEIPT/getSaleReceiptById", id);

      this.$modal.show({
        name: "modal-sale-receipts"
      });

      this.$store.dispatch(
        "SALE_RECEIPT/getSaleReceiptPaymentTransactions",
        id
      );

      this.$store.dispatch(
        "INVOICE_PARTICIPANT/getInvoiceParticipantsOfInvoice",
        {
          modelName: "App\\Models\\HoaDonBan",
          modelId: id
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.col-wrap {
  border-right: 1px solid #e5e5e5;
  min-height: 100%;
  &:last-child {
    border-right: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-wait {
  cursor: wait;
}
.alert-code-export {
  margin: 0;
  padding: 5px 7px;
  font-size: 12px;
  color: #4caf50;
  background: #e8f5e9;
  border-radius: 4px;
}
</style>
