<template>
  <v-card-title class="px-5 py-4">
    <div class="text-h6">Linh kiện thay thế</div>

    <v-spacer></v-spacer>

    <div class="d-flex align-center">
      <v-autocomplete
        v-if="guaranteeVoucher.huy_status !== 1"
        class="text-body-1 rounded-lg"
        clearable
        dense
        :disabled="disabledSearchOption"
        hide-details
        :items="
          productOptionStatusRequest.value === 'loading-searchProductOptions'
            ? []
            : filterSearchProductOptions
        "
        item-text="product_name"
        item-value="id"
        :menu-props="{ maxWidth: '332px' }"
        no-filter
        outlined
        placeholder="Tìm linh kiện"
        prepend-inner-icon="mdi-magnify"
        return-object
        :search-input.sync="productSearchKey"
        @change="selectProductOption($event)"
      >
        <template #no-data>
          <div class="pa-4" style="width: 332px">
            <div
              v-if="
                productOptionStatusRequest.value ===
                  'loading-searchProductOptions'
              "
              class="d-flex justify-center"
            >
              <v-progress-circular
                indeterminate
                color="grey lighten-2"
              ></v-progress-circular>
            </div>
            <template v-else>
              Không có dữ liệu
            </template>
          </div>
        </template>

        <template v-slot:item="data">
          <template>
            <v-list-item-content :title="data.item.name">
              <v-list-item-title
                class="font-weight-medium"
                v-html="data.item.product_name"
              ></v-list-item-title>
              <div class="grey--text text--darken-2 text-subtitle-2">
                <span class="font-weight-bold">{{ data.item.SKU }} - </span>
                <span class="item-subtitle" v-html="data.item.name"></span>
              </div>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </div>
  </v-card-title>
</template>

<script>
import { debounce } from "@/core/utils/debounce";

export default {
  name: "CardTitle",

  data() {
    return {
      productSearchKey: null
    };
  },

  computed: {
    disabledSearchOption() {
      return (
        !!this.guaranteeVoucher.export_id ||
        (this.type === "guarantee" && this.guaranteeVoucher.step === 4)
      );
    },

    filterSearchProductOptions() {
      if (this.guaranteeVoucher.options.length > 0) {
        const arr = this.guaranteeVoucher.options.map(item => item.id);

        return this.searchProductOptions.filter(item => !arr.includes(item.id));
      }
      return this.searchProductOptions;
    },

    guaranteeVoucher() {
      return this.$store.getters["GUARANTEE_VOUCHER/guaranteeVoucher"];
    },

    productOptionStatusRequest() {
      return this.$store.getters["PRODUCT_OPTION/statusRequest"];
    },

    searchProductOptions() {
      return this.$store.getters["PRODUCT_OPTION/searchProductOptions"];
    },

    type() {
      if (!this.$route.query) return "guarantee";

      return this.$route.query.type;
    }
  },

  watch: {
    productSearchKey(val) {
      if (val && val.length > 0) {
        this.debounceGetSearchProductOption();
      }
    }
  },

  created() {
    this.debounceGetSearchProductOption = debounce(
      this.getSearchProductOptions,
      700
    );
  },

  methods: {
    calculateSalePrice(price, promotions) {
      if (!promotions || (promotions && !promotions.promo_type_12))
        return price;

      const promotion = promotions.promo_type_12;

      if (promotion.tk_type === 1) {
        return (parseInt(price) * (100 - parseInt(promotion.tk_percent))) / 100;
      }
      if (promotion.tk_type === 2) {
        return parseInt(price) - parseInt(promotion.tk_money);
      }
    },

    getSearchProductOptions() {
      this.$store.dispatch("PRODUCT_OPTION/searchProductOptions", {
        search: this.productSearchKey
      });
    },

    selectProductOption(item) {
      if (item && item.id) {
        const price = item.price;
        const salePrice = this.calculateSalePrice(price, item.promotions);

        if (this.type === "repair") {
          this.guaranteeVoucher.options.unshift({
            id: item.id,
            SKU: item.SKU,
            product_name: item.product_name,
            name: item.name,
            unit_sold_price: price, // đơn giá
            rebate_amount: price - salePrice, // chiết khấu
            serials: [],
            allSerials: []
          });
        }

        if (this.type === "guarantee") {
          this.guaranteeVoucher.options.unshift({
            id: item.id,
            SKU: item.SKU,
            product_name: item.product_name,
            name: item.name,
            price: price,
            serials: [],
            allSerials: []
          });
        }
      }
    }
  }
};
</script>
