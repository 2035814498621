<template>
  <div class="d_flex col-main flex-column">
    <!-- Start: Step Title -->
    <div
      :class="guaranteeVoucher.step === step ? 'green lighten-5' : null"
      class="col-title rounded tp-border-thin pa-3"
    >
      <span
        :class="
          guaranteeVoucher.step === step ? 'green lighten-3' : 'grey lighten-3'
        "
        class="text-body-1 font-weight-medium col-step"
      >
        {{ step }}
      </span>
      <span class="font-weight-medium">{{ stepList[step - 1].name }}</span>
    </div>
    <!-- End: Step Title -->
    <!-- Start: Step Comments -->
    <div v-if="guaranteeVoucher.id" class="col-comments flex-grow-1 mt-2">
      <!-- Start: Comment Form -->
      <div v-if="guaranteeVoucher.huy_status !== 1" class="comment-form mt-2">
        <v-textarea
          v-model="comment.content"
          append-icon="mdi-send"
          auto-grow
          background-color="grey lighten-4"
          class="text-body-1"
          dense
          flat
          hide-details
          placeholder="Viết bình luận"
          rows="1"
          solo
          @click:append="createComment()"
        ></v-textarea>
      </div>
      <!-- End: Comment Form -->
      <!-- Start: Comment List -->
      <div
        v-if="guaranteeVoucherCommentsOfStep.length > 0"
        class="col-comments--list"
      >
        <div
          v-for="comment in guaranteeVoucherCommentsOfStep"
          :key="comment.id"
          class="d-flex comment-box grey lighten-4 rounded pa-2 mt-2"
        >
          <v-avatar
            class="comment-box--avatar mr-2"
            color="deep-purple lighten-3"
            size="26"
          >
            <img
              v-if="
                comment.created_by_user.avatar &&
                  comment.created_by_user.avatar !== ''
              "
              :alt="comment.created_by_user.name"
              :src="comment.created_by_user.avatar"
            />
            <span v-else class="white--text font-weight-black">{{
              comment.created_by_user.name[0]
            }}</span>
          </v-avatar>
          <div class="comment-box--main">
            <div class="font-weight-bold">
              {{ comment.created_by_user.name }}
            </div>
            <div class="comment-box--main__content">{{ comment.content }}</div>
            <div class="grey--text text--darken-2 mt-1">
              {{ comment.created_at }}
            </div>
          </div>
        </div>
      </div>
      <!-- End: Comment List -->
    </div>
    <!-- End: Step Comments -->
    <!-- Start: Step Action -->
    <div
      v-if="guaranteeVoucher.id && guaranteeVoucher.huy_status !== 1"
      class="d-flex justify-space-between col-actions mt-4"
    >
      <template v-if="guaranteeVoucher.step === step">
        <v-btn
          v-if="
            guaranteeVoucher.step !== 4 ||
              (guaranteeVoucher.step === 4 &&
                isButton === 'repair' &&
                !guaranteeVoucher.order_id)
          "
          class="grey lighten-4 grey--text text--darken-2 rounded-lg"
          depressed
          @click="updateGuaranteeVoucher(guaranteeVoucher.step - 1)"
          >Trở lại
        </v-btn>
        <v-btn
          v-if="guaranteeVoucher.step < 4 && guaranteeVoucher.step !== 3"
          :disabled="
            guaranteeVoucherStatusRequest === 'loading-updateGuaranteeVoucher'
          "
          class="rounded-lg"
          color="primary"
          dark
          depressed
          @click="updateGuaranteeVoucher(guaranteeVoucher.step + 1)"
        >
          Xong
        </v-btn>
        <v-btn
          v-if="guaranteeVoucher.step === 3 && isButton === 'repair'"
          :disabled="
            guaranteeVoucherStatusRequest === 'loading-updateGuaranteeVoucher'
          "
          class="rounded-lg"
          color="primary"
          dark
          depressed
          @click="confirmUpdateLastStep(4)"
        >
          Xong
        </v-btn>
        <v-btn
          v-if="guaranteeVoucher.step === 3 && isButton === 'guarantee'"
          :disabled="
            guaranteeVoucherStatusRequest === 'loading-updateGuaranteeVoucher'
          "
          class="rounded-lg"
          color="primary"
          dark
          depressed
          @click="confirmUpdateLastStep(4)"
        >
          Xong
        </v-btn>
        <div
          v-if="
            guaranteeVoucher &&
              !guaranteeVoucher.export_id &&
              guaranteeVoucher.step === 4 &&
              isButton === 'repair'
          "
        >
          <v-btn
            :loading="
              orderStatusRequest.value === 'loading-exportRepairInvoice'
            "
            class="btn-bill"
            color="primary"
            dark
            @click="handleXuatPhieu"
          >
            Xuất hoá đơn
          </v-btn>
        </div>
      </template>
    </div>
    <!-- End: Step Action -->
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      required: true
    },
    isButton: {
      type: String, // 1 repair, 2 guarantee
      default: "guarantee"
    }
  },
  data() {
    return {
      comment: {
        bh_sc_id: null,
        step: null,
        content: null
      },
      stepList: [
        { id: 1, name: "Đã tiếp nhận" },
        { id: 2, name: "Đang xử lý" },
        { id: 3, name: "Xử lý xong" },
        { id: 4, name: "Đã trả khách" }
      ]
    };
  },
  computed: {
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    guaranteeVoucherStatusRequest() {
      return this.$store.getters["GUARANTEE_VOUCHER/statusRequest"];
    },
    paymentTotal() {
      return this.$store.getters["SALE/paymentTotal"];
    },
    paymentList() {
      return this.$store.getters["SALE/paymentList"];
    },
    offBizDetail() {
      return this.$store.getters["INTERNAL_GUARANTEE_NOTE/offBizDetail"];
    },
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },
    saleReceiptStatusRequest() {
      return this.$store.getters["SALE_RECEIPT/statusRequest"];
    },
    serialInfo() {
      return this.$store.getters["GUARANTEE_VOUCHER/serialInfo"];
    },
    guaranteeVoucher() {
      return this.$store.getters["GUARANTEE_VOUCHER/guaranteeVoucher"];
    },
    guaranteeVoucherTotal() {
      return this.guaranteeVoucher.options.reduce(
        (total, item) =>
          total +
          item.serials.length * (item.unit_sold_price - item.rebate_amount),
        0
      );
    },
    guaranteeVoucherComments() {
      return this.$store.getters[
        "GUARANTEE_VOUCHER_COMMENT/guaranteeVoucherComments"
      ];
    },
    guaranteeVoucherCommentsOfStep() {
      return this.guaranteeVoucherComments.filter(
        comment => comment.step === this.step
      );
    },
    outerSerial() {
      return this.$store.getters["GUARANTEE_VOUCHER/outerSerial"];
    },
    order() {
      return this.$store.getters["ORDER/order"];
    },
    orderStatusRequest() {
      return this.$store.getters["ORDER/statusRequest"];
    }
  },

  methods: {
    async confirmUpdateLastStep() {
      if (
        this.guaranteeVoucher.serial &&
        this.guaranteeVoucher.serial.bought_by &&
        this.guaranteeVoucher.serial.bought_by.name
      ) {
        await this.$store.dispatch("CUSTOMER/getCustomers", {
          cur_page: 1,
          per_page: 10,
          search: this.guaranteeVoucher.serial.bought_by.name
        });
      }

      this.guaranteeVoucher.return_participant_id =
        this.user && this.user.id ? this.user.id : null;

      this.$modal.show({
        name: "modal-confirm-give-customer"
      });

      // this.$modal.show({
      //   name: "modal-alert",
      //   payload: {
      //     title: `<span>Chuyển trạng thái sang đã trả khách</span>`,
      //     message:
      //       "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
      //     confirmBtn: {
      //       color: "red accent-2",
      //       text: "Xong",
      //       onClickHandler: async () => {
      //         await this.updateGuaranteeVoucher(step);
      //       }
      //     }
      //   }
      // });
    },

    async createReceiptVoucher(payment_type, payment_amount) {
      if (!this.serialInfo && this.outerSerial && this.outerSerial.customer) {
        const receiptVoucher = {
          branch_id: this.guaranteeVoucher.branch_id,
          sender: {
            model_name: "App\\Models\\User",
            model_id: this.outerSerial.customer.id
          },
          receiver: {
            model_name: "App\\Models\\User",
            model_id: this.user.id
          },
          type: 1,
          apply_type: 2,
          payment_fee: 0,
          payment_type: payment_type,
          payment_amount: payment_amount,
          voucher_links: [
            {
              model_name: "App\\Models\\HoaDonBan",
              model_id: this.saleReceipt.id,
              amount: payment_amount
            }
          ]
          // ...paymentInfo
        };

        await this.$store.dispatch(
          "RECEIPT_VOUCHER/addCashFlow",
          receiptVoucher
        );
      } else {
        const receiptVoucher = {
          branch_id: this.guaranteeVoucher.branch_id,
          sender: {
            model_name: "App\\Models\\User",
            model_id: this.serialInfo.bought_by.id
          },
          receiver: {
            model_name: "App\\Models\\User",
            model_id: this.user.id
          },
          type: 1,
          apply_type: 2,
          payment_fee: 0,
          payment_type: payment_type,
          payment_amount: payment_amount,
          voucher_links: [
            {
              model_name: "App\\Models\\HoaDonBan",
              model_id: this.saleReceipt.id,
              amount: payment_amount
            }
          ]
          // ...paymentInfo
        };

        await this.$store.dispatch(
          "RECEIPT_VOUCHER/addCashFlow",
          receiptVoucher
        );
      }
    },

    async createComment() {
      this.comment.bh_sc_id = this.guaranteeVoucher.id;
      this.comment.step = this.step;
      // Create comment
      await this.$store.dispatch(
        "GUARANTEE_VOUCHER_COMMENT/createGuaranteeVoucherComment",
        this.comment
      );
      // Reset comment
      this.comment = {
        bh_sc_id: null,
        step: null,
        content: null
      };
    },

    async handleCallRequestXuatPhieu(khanghang_id) {
      if (this.guaranteeVoucherTotal + this.guaranteeVoucher.sc_fee === 0) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Không đủ điều kiện để xuất phiếu"
          }
        });
      } else {
        const details = this.guaranteeVoucher.options.map(item => {
          return {
            option: {
              id: item.id,
              SKU: item.SKU,
              name: item.name,
              product_name: item.product_name,
              unit_sold_price: item.unit_sold_price,
              rebate_amount: item.rebate_amount,
              serials: item.serials
            },
            option_id: item.id,
            qty: item.serials.length,
            unit_sold_price: item.unit_sold_price,
            rebate_amount: item.rebate_amount
          };
        });

        const obj = {
          bhsc_id: this.guaranteeVoucher.id,
          customer_id: khanghang_id,
          khachhang_id: khanghang_id,
          note: this.guaranteeVoucher.code
            ? `Xuất bán cho đơn sửa chữa ` + this.guaranteeVoucher.code
            : "",
          shipping_fee: 0,
          branch_id: this.guaranteeVoucher.branch_id
            ? this.guaranteeVoucher.branch_id
            : null,
          value: this.guaranteeVoucherTotal + this.guaranteeVoucher.sc_fee,
          sc_fee: this.guaranteeVoucher.sc_fee,
          details: details,
          suffix: this.guaranteeVoucher.code,
          transactions: this.guaranteeVoucher.transactions
        };

        // obj.options.forEach(item => (item.allSerials = []));

        // Request create sale receipt
        await this.$store.dispatch("ORDER/exportRepairInvoice", obj);
      }
      if (
        this.orderStatusRequest &&
        this.orderStatusRequest.value === "success-exportRepairInvoice"
      ) {
        // Alert
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Xuất hoá đơn thành công"
          }
        });

        await this.$store.dispatch(
          "GUARANTEE_VOUCHER/getGuaranteeVoucherById",
          this.guaranteeVoucher.id
        );
      }
      if (
        this.orderStatusRequest &&
        this.orderStatusRequest.value === "error-exportRepairInvoice"
      ) {
        this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">${this.orderStatusRequest.message.message}</span>`,
            cancelBtnText: "OK"
          }
        });
      }
    },

    handleXuatPhieu() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xuất hóa đơn</span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Tiếp tục",
            onClickHandler: async () => {
              if (this.serialInfo && this.offBizDetail) {
                const khachhang_id =
                  this.serialInfo.bought_by && this.serialInfo.bought_by.id
                    ? this.serialInfo.bought_by.id
                    : null;

                await this.handleCallRequestXuatPhieu(khachhang_id);
              } else if (this.outerSerial && this.outerSerial.customer_id) {
                await this.handleCallRequestXuatPhieu(
                  this.outerSerial.customer_id
                );
              }
            }
          }
        }
      });
    },

    nextStep() {
      if (this.step < 4) {
        this.guaranteeVoucher.step = this.step + 1;
      }
      if (this.step === 3)
        this.$store.dispatch("GUARANTEE_VOUCHER_COMMENT/handlePrintBill", {
          status: true,
          value: null
        });
    },

    prevStep() {
      if (this.step > 1) {
        this.guaranteeVoucher.step = this.step - 1;
      }
      if (this.step === 4)
        this.$store.dispatch("GUARANTEE_VOUCHER_COMMENT/handlePrintBill", {
          status: false,
          value: null
        });
    },

    async updateGuaranteeVoucher(step) {
      // Set type
      if (this.$route.query.type === "guarantee") {
        this.guaranteeVoucher.type = 1;
      } else {
        this.guaranteeVoucher.type = 2;
      }

      const objectSender = JSON.parse(JSON.stringify(this.guaranteeVoucher));
      let serials = [];

      await objectSender.options.forEach(option => {
        const arr = option.serials.map(item => {
          return {
            id: item.id,
            sold_price:
              this.guaranteeVoucher.type === 1
                ? 0
                : option.unit_sold_price - Number(option.rebate_amount),
            unit_sold_price:
              this.guaranteeVoucher.type === 1 ? null : option.unit_sold_price
          };
        });

        serials = serials.concat(arr);
      });

      objectSender.xuat_serial_ids = serials;
      // objectSender.export_id = this.saleReceipt.id;
      objectSender.outer_serial_id = this.outerSerial
        ? this.outerSerial.id
        : null;
      objectSender.step = step;

      // Request update
      await this.$store.dispatch(
        "GUARANTEE_VOUCHER/updateGuaranteeVoucher",
        objectSender
      );

      if (
        this.guaranteeVoucherStatusRequest.value ===
        "success-updateGuaranteeVoucher"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });
      }
      if (
        this.guaranteeVoucherStatusRequest.value ===
        "error-updateGuaranteeVoucher"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Có lỗi xảy ra. Vui lòng kiểm tra lại sau!"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.col {
  &-main {
    height: 100%;
    overflow: hidden;
  }

  &-step {
    align-items: center;
    border-radius: 50%;
    display: inline-flex;
    font-size: 0.75rem;
    justify-content: center;
    height: 24px;
    margin-right: 0.5rem;
    min-width: 24px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    width: 24px;
  }

  &-actions {
    min-height: 36px;
  }
}

.comment-box {
  &--main {
    flex: 1 1 auto;
    min-width: 0;
    width: 100%;

    &__content {
      white-space: pre-line;
      word-break: break-word;
    }
  }
}
</style>
