<template>
  <div
    class="d-flex flex-row align-end font-weight-medium justify-space-between"
  >
    <div>Tổng vốn linh kiện:</div>
    <div>{{ guaranteeVoucherTotal | formatCurrency }}</div>
  </div>
</template>

<script>
export default {
  name: "TotalGuarantee",

  computed: {
    guaranteeVoucher() {
      return this.$store.getters["GUARANTEE_VOUCHER/guaranteeVoucher"];
    },

    guaranteeVoucherTotal() {
      return this.guaranteeVoucher.options.reduce(
        (total, item) =>
          total +
          item.serials.reduce(
            (createdPriceTotal, serial) =>
              createdPriceTotal + serial.created_price,
            0
          ),
        0
      );
    }
  }
};
</script>
