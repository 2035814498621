<template>
  <div>
    <div @click="visible = true">
      <slot name="activator"></slot>
    </div>

    <v-dialog v-model="visible" max-width="1100">
      <v-card class="border-0" flat>
        <div class="card px-5 py-3 align-center d-flex" height="56x">
          <div class="font-weight-bold">Khách thanh toán</div>

          <v-spacer></v-spacer>

          <v-btn class="mr-2" color="primary" depressed @click="onSaved">
            Xong
          </v-btn>

          <v-btn icon @click="visible = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-card>

      <div
        class="v-window-item tab-item grey lighten-3 pa-5 v-window-item--active"
      >
        <v-card class="pa-5 text-body-1" flat>
          <div class="mb-6" style="width: 50%">
            <div class="mb-3">
              Chi nhánh thanh toán
            </div>

            <tp-select
              v-model="paymentBranch"
              :items="branches"
              clearable
              dense
              hide-details="auto"
              item-text="name"
              item-value="id"
              outlined
              placeholder="Chọn chi nhánh"
              solo
            ></tp-select>
          </div>

          <div class="mb-5" style="width: 50%">
            <div class="d-flex justify-space-between">
              <div>Khách cần trả</div>
              <div class="font-weight-bold">
                {{ totalAmountToPay | formatCurrency }}
              </div>
            </div>

            <v-divider class="my-4"></v-divider>

            <div class="d-flex justify-space-between">
              <div>Khách thanh toán</div>
              <div class="font-weight-bold">
                {{ totalAmount | formatCurrency }}
              </div>
            </div>
          </div>

          <tp-form-transactions-payment
            ref="formRef"
            :branch-id="paymentBranch"
            :model-id="guaranteeVoucher.id"
            :model-type="LIST_MODEL_TYPES.BHSC"
            @change="onUpdateTransactions"
          ></tp-form-transactions-payment>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { LIST_MODEL_TYPES } from "@/core/constant";

export default {
  data() {
    return {
      paymentBranch: this.branchId,
      visible: false,
      payments: [],
      LIST_MODEL_TYPES
    };
  },

  props: {
    branchId: {
      type: Number,
      require: true
    },
    totalAmountToPay: {
      type: Number,
      require: true,
      default: 0
    }
  },

  computed: {
    guaranteeVoucher() {
      return this.$store.getters["GUARANTEE_VOUCHER/guaranteeVoucher"];
    },
    totalAmount() {
      if (!this.payments.length) return 0;

      return this.payments.reduce((sum, transaction) => {
        const transactionSum =
          (transaction.payment_fee || 0) + (transaction.amount || 0);
        return sum + transactionSum;
      }, 0);
    },
    branches() {
      return this.$store.getters["BRANCH/allSaleBranches"];
    }
  },

  methods: {
    onSaved() {
      if (!this.paymentBranch) {
        return this.$modal.show({
          name: "modal-alert",
          payload: {
            title: `<span class="red--text text--accent-2">Vui lòng chọn chi nhánh</span>`,
            cancelBtnText: "OK"
          }
        });
      }

      const isValid = this.$refs.formRef.$refs.formRef.validate();

      if (!isValid) return;

      const payload = {
        payments: this.payments,
        total: this.totalAmount,
        branchId: this.paymentBranch
      };

      this.$emit("onSaved", payload);
      this.visible = false;
    },

    onUpdateTransactions(value) {
      this.payments = value;
    }
  }
};
</script>
