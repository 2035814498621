<template>
  <v-row class="d-flex flex-row align-end justify-space-between mt-2">
    <!-- Start: Name -->
    <v-col class="px-3 py-0">
      <v-hover v-slot:default="{ hover }">
        <v-icon
          class="icon-remove pr-1"
          style="margin-bottom: 2px"
          :color="hover ? 'red accent-2' : 'grey lighten-1'"
          size="18px"
          @click="removePaymentMethod"
          >mdi-trash-can-outline</v-icon
        >
      </v-hover>
      {{ paymentMethodList[paymentMethod.payment_type - 1].name }}
    </v-col>
    <!-- End: Name -->
    <!-- Start: 2 & 3 - Receive account, 4 - Shipping partner, 5 - Installment method -->
    <v-col
      class="px-3 py-0"
      :cols="
        paymentMethod.payment_type === 3 || paymentMethod.payment_type === 4
          ? '6'
          : null
      "
    >
      <!-- Start: Swiping payment accounts -->
      <v-autocomplete
        v-if="paymentMethod.payment_type === 2"
        v-model="paymentMethod.payment_account_id"
        class="text-body-1 rounded mt-0"
        clearable
        dense
        flat
        hide-details
        :items="swipingPaymentAccount"
        item-text="account_number"
        item-value="id"
        no-data-text="Không có dữ liệu"
        placeholder="Tài khoản nhận"
        :rules="[rules.required]"
      >
        <template v-slot:item="data">
          <div class="py-1">
            <div class="font-weight-medium">
              {{ data.item.account_number }} - {{ data.item.account_owner }}
            </div>
            <div class="text-body-2">{{ data.item.account_bank }}</div>
          </div>
        </template>
      </v-autocomplete>
      <!-- End: Swiping payment accounts -->
      <!-- Start: Banking payment accounts -->
      <v-autocomplete
        clearable
        v-if="paymentMethod.payment_type === 3"
        v-model="paymentMethod.payment_account_id"
        class="text-body-1 rounded mt-0"
        dense
        flat
        hide-details
        :items="bankingPaymentAccount"
        item-text="account_number"
        item-value="id"
        no-data-text="Không có dữ liệu"
        placeholder="Tài khoản nhận"
        :rules="[rules.required]"
      >
        <template v-slot:item="data">
          <div class="py-1">
            <div class="font-weight-medium">
              {{ data.item.account_number }} - {{ data.item.account_owner }}
            </div>
            <div class="text-body-2">{{ data.item.account_bank }}</div>
          </div>
        </template>
      </v-autocomplete>
      <!-- End: Banking payment accounts -->
      <!-- Start: Shipping partners -->
      <v-select
        v-if="paymentMethod.payment_type === 4"
        v-model="paymentMethod.shipping_partner"
        class="text-body-1 rounded mt-0"
        clearable
        dense
        flat
        hide-details
        :items="shippingPartners"
        item-text="name"
        item-value="id"
        :menu-props="{ nudgeTop: '-40px' }"
        no-data-text="Không có dữ liệu"
        placeholder="Đối tác vận chuyển"
        :rules="[rules.required]"
      >
      </v-select>
      <!-- End: Shipping partners -->
      <!-- Start: Installment partners -->
      <v-select
        v-if="paymentMethod.payment_type === 5"
        v-model="paymentMethod.installment_method"
        class="text-body-1 rounded mt-0"
        clearable
        dense
        flat
        hide-details
        :items="installmentPartner"
        :menu-props="{ nudgeTop: '-40px' }"
        no-data-text="Không có dữ liệu"
        placeholder="Phương thức"
        :rules="[rules.required]"
      >
      </v-select>
      <!-- End: Installment partners -->
    </v-col>
    <!-- End: 2 & 3 - Receive account, 4 - Shipping partner, 5 - Installment method -->
    <!-- Start: 2 - Card swiping fee, 5 - Receive account -->
    <v-col
      v-if="
        paymentMethod.payment_type === 2 || paymentMethod.payment_type === 5
      "
      class="px-3 py-0"
    >
      <tp-input-price
        v-if="paymentMethod.payment_type === 2"
        custom-class="text-body-1 mt-0 pt-0"
        dense
        :disabled="disabledPaymentFee || !paymentMethod.payment_account_id"
        hide-details
        placeholder="Phí quẹt thẻ"
        :readonly="disabledPaymentFee"
        :rules="[rules.required]"
        :value="paymentMethod.payment_fee"
        @change="paymentMethod.payment_fee = $event"
      ></tp-input-price>
      <!-- Start: Installment payment accounts -->
      <v-autocomplete
        v-if="paymentMethod.payment_type === 5"
        v-model="paymentMethod.payment_account_id"
        class="text-body-1 rounded mt-0"
        dense
        :disabled="!paymentMethod.installment_method"
        flat
        hide-details
        :items="installmentPaymentAccounts"
        item-text="account_owner"
        item-value="id"
        :menu-props="{ maxWidth: '332px' }"
        no-data-text="Không có dữ liệu"
        :placeholder="
          !paymentMethod.installment_method
            ? 'Chưa chọn phương thức'
            : 'Tài khoản nhận'
        "
        return-object
        :rules="[rules.required]"
      >
      </v-autocomplete>
      <!-- End: Installment payment accounts -->
    </v-col>
    <!-- End: 2 - Card swiping fee, 5 - Receive account -->
    <!-- Start: Payment amount -->
    <v-col class="px-3 py-0" cols="3">
      <tp-input-price
        custom-class="text-body-1 price--text-right mt-0 pt-0"
        dense
        hide-details
        :disabled="parseInt(isShowMoneyInput()) === 0"
        placeholder="Số tiền"
        :rules="[rules.required]"
        :value="paymentMethod.payment_amount"
        @change="paymentMethod.payment_amount = $event"
      ></tp-input-price>
    </v-col>
    <!-- End: Payment amount -->
  </v-row>
</template>

<script>
import { LIST_SUPPLIER_CATEGORIES } from "@/modules/PartnerAndMember/constant";

export default {
  props: {
    disabledPaymentFee: {
      type: Boolean,
      default: false
    },
    paymentMethod: {
      type: Object,
      require: true
    },
    paymentMethodIndex: {
      type: Number,
      required: true
    },
    paymentMethodList: {
      type: Array,
      require: true
    }
  },
  data() {
    return {
      installmentPartner: ["mPos", "Alepay", "HD Bank"],
      rules: {
        required: value => !!value || "Không được bỏ trống."
      },
      supplierCategories: LIST_SUPPLIER_CATEGORIES
    };
  },
  computed: {
    guaranteeVoucher() {
      return this.$store.getters["GUARANTEE_VOUCHER/guaranteeVoucher"];
    },
    bankingPaymentAccount() {
      return this.paymentAccounts.filter(item =>
        item.received_methods.includes("CHUYEN_KHOAN_TRUC_TIEP")
      );
    },
    installmentPaymentAccounts() {
      return this.paymentAccounts.filter(item => {
        const foundInstallmentMethod = item.received_methods.find(
          item => typeof item === "object"
        );

        return !!(
          foundInstallmentMethod &&
          foundInstallmentMethod.TRA_GOP.includes(
            this.paymentMethod.installment_method
          )
        );
      });
    },
    paymentAccounts() {
      const paymentAccounts = this.$store.getters[
        "PAYMENT_ACCOUNT/paymentAccounts"
      ];
      const paymentAccountsByBranch = paymentAccounts.filter(item => {
        return item.available_branches.includes(
          this.guaranteeVoucher.branch_id
        );
      });
      return paymentAccountsByBranch;
    },
    paymentList() {
      return this.$store.getters["SALE/paymentList"];
    },
    shippingPartners() {
      return this.$store.getters["SUPPLIER/allSuppliers"];
    },
    swipingPaymentAccount() {
      return this.paymentAccounts.filter(item =>
        item.received_methods.includes("QUET_THE")
      );
    },
    saleTabActive() {
      return this.$store.getters["SALE/saleTabActive"];
    }
  },
  async created() {
    await this.$store.dispatch(
      "SUPPLIER/getAllSuppliers",
      `?sup_cate_ids=${this.supplierCategories[2].id}`
    );
  },
  methods: {
    removePaymentMethod() {
      this.paymentList.splice(this.paymentMethodIndex, 1);

      // Update payment list in state
      this.$store.dispatch("SALE/setPaymentList", this.paymentList);
    },
    isShowMoneyInput() {
      switch (this.paymentMethod.payment_type) {
        case 1:
          return 1;
        case 2:
          if (this.paymentMethod.payment_account_id) {
            return 1;
          }
          return 0;
        case 3:
          if (this.paymentMethod.payment_account_id) {
            return 1;
          }
          return 0;
        case 4:
          if (this.paymentMethod.shipping_partner) {
            return 1;
          }
          return 0;
        case 5:
          if (
            this.paymentMethod.installment_method &&
            this.paymentMethod.payment_account_id
          ) {
            return 1;
          }
          return 0;
      }
    }
  }
};
</script>
