var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" Xử lý "+_vm._s(_vm.type === "guarantee" ? "bảo hành" : "sửa chữa")+" ")]),(_vm.guaranteeVoucher.huy_status && _vm.guaranteeVoucher.huy_status === 1)?_c('v-chip',{staticClass:"white font-weight-bold ml-3",attrs:{"color":"red accent-2","outlined":""}},[_vm._v(" Đã hủy ")]):_vm._e(),_c('v-spacer'),(_vm.isShowCancelBtn)?_c('v-btn',{staticClass:"red lighten-4 red--text text-accent-2 rounded-lg mr-3",attrs:{"depressed":"","disabled":_vm.guaranteeVoucherStatusRequest.value ===
          'loading-cancelGuaranteeVoucher'},on:{"click":function($event){_vm.guaranteeVoucherStatusRequest.value ===
        'loading-cancelGuaranteeVoucher'
          ? null
          : _vm.openModalCancelingGuaranteeVoucher()}}},[_vm._v(" Hủy ")]):_vm._e(),(
        _vm.guaranteeVoucher.id &&
          _vm.guaranteeVoucher.huy_status !== 1 &&
          _vm.guaranteeVoucher.step < 4
      )?_c('v-btn',{staticClass:"rounded-lg",attrs:{"color":"primary","depressed":"","disabled":_vm.guaranteeVoucherStatusRequest.value ===
          'loading-updateGuaranteeVoucher'},on:{"click":function($event){_vm.guaranteeVoucherStatusRequest.value ===
        'loading-updateGuaranteeVoucher'
          ? null
          : _vm.updateGuaranteeVoucher()}}},[_vm._v(" Lưu ")]):_vm._e()],1),(_vm.guaranteeVoucher.huy_status && _vm.guaranteeVoucher.huy_status === 1)?_c('v-card',{staticClass:"mt-5",attrs:{"flat":""}},[_c('div',{staticClass:"amber lighten-5 text-body-1 px-3 py-2"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Lý do hủy:")]),_vm._v(" "+_vm._s(_vm.guaranteeVoucher.ly_do_huy)+" ")])]):_vm._e(),_c('div',{staticClass:"main-detail--content mt-5"},[_c('card-replace-accessories',{staticClass:"card-accessories",attrs:{"type":_vm.type,"payment-total":_vm.paymentTotal}}),_c('card-processing',{staticClass:"card-processing",attrs:{"type":_vm.type}})],1),_c('modal-sale-payment')],1)}
var staticRenderFns = []

export { render, staticRenderFns }