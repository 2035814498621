<template>
  <tbody>
    <tr v-for="(item, index) in guaranteeVoucher.options" :key="item.id">
      <td>{{ index + 1 }}</td>
      <!-- Start: Product info -->
      <td>
        <div class="my-2">
          <div class="font-weight-medium">
            {{ item.product_name }}
          </div>
          <div
            class="text-subtitle-2 font-weight-light grey--text text--darken-2"
          >
            <span class="font-weight-bold">{{ item.SKU }} - </span>
            <span class="item-subtitle mr-1" v-html="item.name"></span>
          </div>
        </div>
      </td>
      <!-- End: Product info -->
      <!-- Start: Serials -->
      <td>
        <v-autocomplete
          v-model="item.serials"
          class="tp-filter-autocomplete text-body-1 my-2"
          dense
          deletable-chips
          :disabled="disableEditOption"
          :items="item.allSerials"
          item-text="serial_number"
          item-value="id"
          hide-details
          hide-selected
          multiple
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Chọn serials"
          single-line
          small-chips
          return-object
          @focus="getSerialsByOptionId(item)"
        >
        </v-autocomplete>
      </td>
      <!-- End: Serials -->
      <!-- Start: Quantity -->
      <td class="text-center">{{ item.serials.length }}</td>
      <!-- End: Quantity -->
      <!-- Start: Created Price -->
      <td v-if="!isSalePermission && !isMarketingPermission">
        {{ calculateAverageCreatedPrice(item.serials) | formatCurrency }}
      </td>
      <!-- End: Created Price -->
      <!-- Start: Total -->
      <td v-if="!isSalePermission && !isMarketingPermission">
        {{ calculateCreatedPriceTotal(item.serials) | formatCurrency }}
      </td>
      <!-- End: Total -->
      <!-- Start: Action -->
      <td v-if="!disableEditOption">
        <v-hover v-slot:default="{ hover }">
          <v-btn
            :color="hover ? 'grey darken-1' : 'grey lighten-1'"
            icon
            small
            @click="removeProductOption(index)"
          >
            <v-icon size="18px">mdi-trash-can-outline</v-icon>
          </v-btn>
        </v-hover>
      </td>
      <!-- End: Action -->
    </tr>
  </tbody>
</template>

<script>
export default {
  name: "DataTableBodyGuarantee",

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },

    disableEditOption() {
      return (
        this.guaranteeVoucher.huy_status === 1 ||
        this.guaranteeVoucher.step === 4
      );
    },

    guaranteeVoucher() {
      return this.$store.getters["GUARANTEE_VOUCHER/guaranteeVoucher"];
    },

    productOptionStockSerials() {
      return this.$store.getters["SERIAL/productOptionStockSerials"];
    },

    type() {
      if (!this.$route.query) return "guarantee";

      return this.$route.query.type;
    },

    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },

  methods: {
    calculateAverageCreatedPrice(serials) {
      if (serials.length === 0) return 0;

      return Math.round(
        this.calculateCreatedPriceTotal(serials) / serials.length
      );
    },

    calculateCreatedPriceTotal(serials) {
      return serials.reduce(
        (total, item) => total + Number(item.created_price),
        0
      );
    },

    async getSerialsByOptionId(option) {
      if (option.allSerials.length === 0) {
        let res = [];

        await this.$store.dispatch(
          "SERIAL/getStockSerialsByOptionId",
          option.id
        );

        const sortBranches = this.branches.sort((a, b) => {
          if (a.id === this.guaranteeVoucher.branch_id) return -1;
          if (b.id === this.guaranteeVoucher.branch_id) return 1;
          return 0;
        });

        sortBranches.forEach(branch => {
          const serialsNotSold = this.productOptionStockSerials.filter(
            item => item.status === 1
          );
          // Filter serial by branch id
          let arr = serialsNotSold.filter(item => item.branch_id === branch.id);

          if (
            this.guaranteeVoucher.branch_id &&
            this.guaranteeVoucher.branch_id !== branch.id
          ) {
            arr.forEach(item => {
              item.disabled = true;
            });
          }

          // Push into res array
          if (arr.length > 0) {
            res.push({ header: `${branch.name} (${arr.length})` });
            res = res.concat(arr);
            // Push divider
            // if (index < this.branches.length - 1) {
            //   res.push({ divider: true });
            // }
          }
        });

        option.allSerials = res;
      }
    },

    removeProductOption(index) {
      if (
        this.guaranteeVoucher &&
        !this.guaranteeVoucher.export_id &&
        this.type === "repair"
      )
        this.guaranteeVoucher.options.splice(index, 1);
      if (
        this.guaranteeVoucher &&
        this.guaranteeVoucher.step !== 4 &&
        this.type === "guarantee"
      )
        this.guaranteeVoucher.options.splice(index, 1);
    }
  }
};
</script>
