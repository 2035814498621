<template>
  <v-card flat>
    <card-title></card-title>

    <v-data-table
      class="table-scroll tp-table__row-pointer datatable px-5 py-0"
      calculate-widths
      disable-pagination
      no-data-text="Không có dữ liệu"
      no-results-text="Không tìm thấy kết quả phù hợp"
      :headers="headers"
      hide-default-footer
      :loading="false"
      loading-text="Đang tải dữ liệu"
      :items="guaranteeVoucher.options"
      item-key="id"
    >
      <template v-slot:body="{ items }">
        <template v-if="items.length > 0">
          <data-table-body-repair
            v-if="type === 'repair'"
          ></data-table-body-repair>

          <data-table-body-guarantee v-else></data-table-body-guarantee>
        </template>

        <tbody v-else>
          <tr class="v-data-table__empty-wrapper">
            <td :colspan="headers.length">Không có dữ liệu</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <v-divider class="mx-5 mb-3"></v-divider>

    <div class="px-5 pb-5">
      <v-row class="justify-md-end mx-0">
        <v-col class="pa-0" cols="12" md="6">
          <total-guarantee
            v-if="
              type === 'guarantee' &&
                !isSalePermission &&
                !isMarketingPermission
            "
          ></total-guarantee>

          <total-repair
            v-if="type === 'repair'"
            :payment-total="paymentTotal"
          ></total-repair>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import {
  HEADERS_GUARANTEE_ACCESSORIES,
  HEADERS_GUARANTEE_ACCESSORIES_FULL,
  HEADERS_REPAIR_ACCESSORIES
} from "@/modules/Guarantee/constants";

import CardTitle from "./CardTitle";
import DataTableBodyGuarantee from "./DataTableBodyGuarantee";
import DataTableBodyRepair from "./DataTableBodyRepair";
import TotalGuarantee from "./TotalGuarantee";
import TotalRepair from "./TotalRepair";

export default {
  components: {
    CardTitle,
    DataTableBodyGuarantee,
    DataTableBodyRepair,
    TotalGuarantee,
    TotalRepair
  },

  props: {
    type: {
      type: String,
      require: true
    },
    paymentTotal: {
      type: Number,
      require: true
    }
  },

  computed: {
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },

    guaranteeVoucher() {
      return this.$store.getters["GUARANTEE_VOUCHER/guaranteeVoucher"];
    },

    headers() {
      if (this.type === "guarantee") {
        if (this.isSalePermission || this.isMarketingPermission) {
          return HEADERS_GUARANTEE_ACCESSORIES;
        } else {
          return HEADERS_GUARANTEE_ACCESSORIES_FULL;
        }
      }

      return HEADERS_REPAIR_ACCESSORIES;
    },

    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    }
  },

  async created() {
    if (this.branches.length === 0) {
      await this.$store.dispatch("BRANCH/getAllBranches");
    }

    await this.$store.dispatch("SUPPLIER/getSuppliers", {
      cur_page: 1,
      filter: {
        fromBalance: null,
        fromBuy: null,
        fromDate: null,
        fromReturn: null,
        sup_cate_ids: [2],
        toBalance: null,
        toBuy: null,
        toDate: null,
        toReturn: null
      },
      per_page: 50,
      search: null
    });
  },

  methods: {
    async getSerialsByOptionId(option) {
      if (option.allSerials.length === 0) {
        let res = [];

        await this.$store.dispatch("SERIAL/getSerialsByOptionId", option.id);

        this.branches.forEach(branch => {
          const serialsNotSold = this.productOptionSerials.filter(
            item => item.status === 1
          );
          // Filter serial by branch id
          let arr = serialsNotSold.filter(item => item.branch_id === branch.id);

          if (
            this.guaranteeVoucher.branch_id &&
            this.guaranteeVoucher.branch_id !== branch.id
          ) {
            arr.forEach(item => {
              item.disabled = true;
            });
          }

          // Push into res array
          if (arr.length > 0) {
            res.push({ header: `${branch.name} (${arr.length})` });
            res = res.concat(arr);
            // Push divider
            // if (index < this.branches.length - 1) {
            //   res.push({ divider: true });
            // }
          }
        });

        option.allSerials = res;
      }
    },

    indexOfOptionIds(value) {
      return this.optionIds.indexOf(value);
    }
  }
};
</script>
