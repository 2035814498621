<template>
  <div>
    <div
      class="d-flex flex-row align-end font-weight-medium justify-space-between"
    >
      <div>Tổng tiền linh kiện:</div>
      <div>{{ guaranteeVoucherTotal | formatCurrency }}</div>
    </div>

    <div
      class="d-flex flex-row align-end font-weight-medium justify-space-between"
    >
      <div class="pt-3">Phí thu của khách hàng:</div>
      <div>
        <tp-input-price
          v-model="guaranteeVoucher.sc_fee"
          :disabled="
            !!guaranteeVoucher.export_id || guaranteeVoucher.huy_status === 1
          "
          custom-class="font-weight-medium text-body-1 price--text-right mt-0 pt-0"
          custom-style="max-width: 170px; width: 170px;"
          hide-details
        ></tp-input-price>
      </div>
    </div>

    <v-divider class="my-3"></v-divider>

    <div
      class="d-flex flex-row align-end font-weight-medium justify-space-between mt-3"
    >
      <div class="font-weight-bold">Khách cần trả:</div>
      <div class="font-weight-bold red--text">
        {{ (guaranteeVoucherTotal + guaranteeVoucher.sc_fee) | formatCurrency }}
      </div>
    </div>

    <div
      v-if="guaranteeVoucher.step === 4 && !guaranteeVoucher.export_id"
      class="d-flex flex-row align-center justify-space-between mt-3"
    >
      <div class="mr-3">
        <div class="font-weight-medium">
          Khách thanh toán:
        </div>
      </div>

      <div class="d-flex flex-row align-center">
        <modal-payment-transactions
          :branch-id="guaranteeVoucher.branch_id"
          :total-amount-to-pay="guaranteeVoucherTotal + guaranteeVoucher.sc_fee"
          @onSaved="handleSavedTransaction"
        >
          <template #activator>
            <v-hover
              v-if="
                !guaranteeVoucher.export_id && guaranteeVoucher.huy_status !== 1
              "
              v-slot:default="{ hover }"
            >
              <v-icon
                :color="hover ? 'primary' : 'grey darken-1'"
                class="icon-card mr-2"
              >
                mdi-credit-card-outline
              </v-icon>
            </v-hover>
          </template>
        </modal-payment-transactions>

        <tp-input-price
          :disabled="
            !!guaranteeVoucher.export_id || guaranteeVoucher.huy_status === 1
          "
          :value="totalAmountTransaction"
          custom-class="font-weight-medium text-body-1 price--text-right mt-0 pt-0"
          custom-style="max-width: 170px; width: 170px;"
          hide-details
          readonly
        ></tp-input-price>
      </div>
    </div>
  </div>
</template>

<script>
import ModalPaymentTransactions from "./ModalPaymentTransactions.vue";
import { LIST_PAYMENT_KIND } from "@/core/constant";

export default {
  name: "TotalRepair",

  components: {
    ModalPaymentTransactions
  },

  props: {
    paymentTotal: {
      type: Number,
      require: true
    }
  },

  data() {
    return {
      totalAmountTransaction: 0
    };
  },

  computed: {
    guaranteeVoucher() {
      return this.$store.getters["GUARANTEE_VOUCHER/guaranteeVoucher"];
    },

    outerSerial() {
      return this.$store.getters["GUARANTEE_VOUCHER/outerSerial"];
    },

    serialInfo() {
      return this.$store.getters["SERIAL/serialInfo"];
    },

    guaranteeVoucherTotal() {
      return this.guaranteeVoucher.options.reduce(
        (total, item) =>
          total +
          item.serials.length * (item.unit_sold_price - item.rebate_amount),
        0
      );
    }
  },

  methods: {
    openModalSalePayment() {
      if (this.guaranteeVoucher && !this.guaranteeVoucher.export_id) {
        this.$modal.show({ name: "modal-sale-payment-bhsc" });
      }
    },

    handleSavedTransaction(value) {
      this.guaranteeVoucher.transactions = value.payments
        .filter(item => item.amount > 0)
        .map(item => {
          let obj = {
            branch_id: value.branchId,
            transactionable_type: "App\\Models\\HoaDonBan",
            senderable_type: "App\\Models\\User",
            sender_bank_id: null,
            receiverable_type:
              item.kind === LIST_PAYMENT_KIND.TIEN_MAT.id
                ? "App\\Models\\Branch"
                : LIST_PAYMENT_KIND.CHUYEN_KHOAN.id
                ? null
                : "App\\Models\\Supplier",
            ...item
          };

          if (obj.receiverable_type === "App\\Models\\Branch") {
            obj.receiverable_id = this.guaranteeVoucher.branch_id;
          }

          return obj;
        });
      this.totalAmountTransaction = value.total;
    }
  }
};
</script>
