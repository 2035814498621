import { render, staticRenderFns } from "./ModalConfirmGiveCustomer.vue?vue&type=template&id=5809ac08&scoped=true&"
import script from "./ModalConfirmGiveCustomer.vue?vue&type=script&lang=js&"
export * from "./ModalConfirmGiveCustomer.vue?vue&type=script&lang=js&"
import style0 from "./ModalConfirmGiveCustomer.vue?vue&type=style&index=0&id=5809ac08&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5809ac08",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VAlert,VAutocomplete,VBtn,VCard,VCheckbox,VCol,VDivider,VIcon,VRow,VSpacer,VTextarea,VToolbarTitle})
