<template>
  <tp-modal name="modal-guarantee-voucher-cancel" max-width="480px">
    <v-card slot-scope="props">
      <v-card-title class="">
        Hủy phiếu
        {{
          props.payload.item && props.payload.item.type === 1
            ? "bảo hành"
            : "sửa chữa"
        }}
        #{{ props.payload.item && props.payload.item.code }}
      </v-card-title>

      <v-card-text class="text-body-1">
        <div>
          Hành động này sẽ không thể hoàn tác. Nhập lý do hủy vào ô bên dưới nếu
          bạn chắc chắn muốn tiếp tục:
        </div>
        <v-textarea
          v-model="cancelReason"
          class="text-body-1 mt-2"
          auto-grow
          hide-details
          placeholder="Nhập lý do hủy tại đây"
          outlined
          rows="3"
          single-line
        ></v-textarea>
      </v-card-text>

      <v-card-actions class="px-5 pb-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn text color="grey darken-1" @click="closeModal()">
          Bỏ qua
        </v-btn>
        <v-btn
          text
          color="red accent-2"
          :disabled="
            !cancelReason || (cancelReason && cancelReason.length === 0)
          "
          @click="cancelGuaranteeVoucher(props.payload.item)"
        >
          Hủy phiếu
        </v-btn>
      </v-card-actions>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  data() {
    return {
      cancelReason: null
    };
  },
  computed: {
    guaranteeVoucherStatusRequest() {
      return this.$store.getters["GUARANTEE_VOUCHER/statusRequest"];
    }
  },
  methods: {
    async cancelGuaranteeVoucher(item) {
      if (
        !this.cancelReason ||
        (this.cancelReason && this.cancelReason.trim() === "")
      ) {
        this.cancelReason = null;
        return false;
      }

      await this.$store.dispatch("GUARANTEE_VOUCHER/cancelGuaranteeVoucher", {
        id: item.id,
        ly_do_huy: this.cancelReason
      });
      // Alert
      if (
        this.guaranteeVoucherStatusRequest.value ===
        "success-cancelGuaranteeVoucher"
      ) {
        this.closeModal();
        await this.$router.push({ name: "guarantee_vouchers" });

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message:
              item.type === 1
                ? "Hủy phiếu bảo hành thành công"
                : "Hủy phiếu sửa chữa thành công"
          }
        });
      } else if (
        this.guaranteeVoucherStatusRequest.value ===
        "error-cancelGuaranteeVoucher"
      ) {
        this.closeModal();
        // await this.$router.push({ name: "guarantee_vouchers" });

        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message:
              item.type === 1
                ? "Lỗi hủy phiếu bảo hành"
                : "Lỗi hủy phiếu sửa chữa"
          }
        });
      }
    },

    closeModal() {
      this.$modal.hide({ name: "modal-guarantee-voucher-cancel" });
    }
  }
};
</script>

<style lang="scss" scoped></style>
